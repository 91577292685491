var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "", "bg-variant": "light" } },
    [
      _c(
        "b-card-header",
        { staticClass: "p-1" },
        [
          _c("b-card-title", { staticClass: "m-0" }, [
            _c("h6", { staticClass: "m-0 pl-2 text-left" }, [
              _c("strong", [_vm._v("Message History")])
            ])
          ])
        ],
        1
      ),
      _c("b-table", {
        staticClass: "mb-0",
        attrs: {
          id: "messageListTable",
          striped: "",
          hover: "",
          small: "",
          bordered: "",
          "sticky-header": "300px",
          fields: _vm.fields,
          items: _vm.messages,
          "primary-key": "id",
          "tbody-tr-class": _vm.rowClass,
          "details-td-class": "w-100 p-0"
        },
        on: { "row-clicked": _vm.view },
        scopedSlots: _vm._u([
          {
            key: "table-colgroup",
            fn: function() {
              return [
                _c("col", { attrs: { width: "15%" } }),
                _c("col", { attrs: { width: "20%" } }),
                _c("col"),
                _c("col", { attrs: { width: "0*" } })
              ]
            },
            proxy: true
          },
          {
            key: "cell(id)",
            fn: function(data) {
              return [
                _c("b-icon-info-circle-fill", {
                  staticClass: "pointer",
                  attrs: { size: "1", variant: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.view(data.item)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _vm.selectedMessage
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "message-view-modal",
                size: "lg",
                title: "Message ID " + _vm.selectedMessage.id,
                "hide-footer": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "button-size": "sm"
              },
              on: {
                close: function($event) {
                  _vm.selectedMessage = null
                }
              },
              model: {
                value: _vm.showMessageDetail,
                callback: function($$v) {
                  _vm.showMessageDetail = $$v
                },
                expression: "showMessageDetail"
              }
            },
            [
              _c("MessageView", {
                attrs: { message: _vm.selectedMessage },
                on: {
                  update: _vm.updateMessage,
                  close: function($event) {
                    return _vm.$root.$emit(
                      "bv::hide::modal",
                      "message-view-modal"
                    )
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }