var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.send.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-for": "message",
                "label-cols": "4",
                "label-cols-sm": "3",
                "label-cols-md": "2",
                "label-align": "right"
              }
            },
            [
              _vm.role === "bookkeeper" || _vm.role === "admin"
                ? _c("b-form-textarea", {
                    ref: "message",
                    model: {
                      value: _vm.message.content,
                      callback: function($$v) {
                        _vm.$set(_vm.message, "content", $$v)
                      },
                      expression: "message.content"
                    }
                  })
                : _c("b-form-textarea", {
                    ref: "message",
                    attrs: { required: "" },
                    model: {
                      value: _vm.message.content,
                      callback: function($$v) {
                        _vm.$set(_vm.message, "content", $$v)
                      },
                      expression: "message.content"
                    }
                  })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Documents:",
                "label-for": "documents",
                "label-cols": "4",
                "label-cols-sm": "3",
                "label-cols-md": "2",
                "label-align": "right"
              }
            },
            [
              _c("b-form-file", {
                attrs: { multiple: "" },
                model: {
                  value: _vm.message.files,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "files", $$v)
                  },
                  expression: "message.files"
                }
              })
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "between" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-left",
                  attrs: { offset: "4", "offset-sm": "3", "offset-md": "2" }
                },
                [_vm._t("default")],
                2
              ),
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        type: "button",
                        variant: "secondary",
                        size: "sm",
                        disabled: _vm.loading
                      },
                      on: { click: _vm.close }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "x" } }),
                      _vm._v(" Cancel ")
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        type: "submit",
                        variant: "success",
                        size: "sm",
                        disabled: _vm.loading
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "check" } }),
                      _vm._v(" Send ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }