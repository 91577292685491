var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transaction
    ? _c(
        "div",
        [
          _vm.transaction.messages && _vm.transaction.messages.length
            ? _c(
                "b-row",
                {
                  staticClass: "m-0 px-3 pb-0 pt-1",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "b-col",
                    [
                      _c("MessageList", {
                        attrs: {
                          messages: _vm.transaction.messages,
                          total: _vm.transaction.messages.length
                        },
                        on: { update: _vm.updateMessage }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "m-0 px-3 pt-3 pb-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-left",
                  staticStyle: {
                    color: "#BE191E",
                    "padding-left": "265px",
                    "font-size": "large"
                  },
                  attrs: { cols: "12", md: "4" }
                },
                [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        "Please provide more detail regarding this transaction."
                      )
                    ])
                  ]),
                  _c("ul", [
                    _c("li", [_c("strong", [_vm._v("Itemized breakdown")])]),
                    _c("li", [_c("strong", [_vm._v("Purpose")])]),
                    _c("li", [_c("strong", [_vm._v("Justification")])])
                  ])
                ]
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "MessageSend",
                    {
                      attrs: {
                        "transaction-id": +_vm.transaction.id,
                        role: _vm.role
                      },
                      on: {
                        close: function($event) {
                          return _vm.toggleDetailRow(_vm.transaction)
                        },
                        refresh: function($event) {
                          return _vm.$emit("refresh")
                        }
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.viewTransaction(_vm.transaction)
                            }
                          }
                        },
                        [_vm._v("View Transaction Detail")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "m-0 pt-2",
              attrs: { "no-gutters": "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleDetailRow(_vm.transaction)
                }
              }
            },
            [
              _c(
                "b-col",
                { staticClass: "border-top px-0" },
                [
                  _c("b-icon-chevron-double-up", {
                    staticClass: "pointer",
                    attrs: { size: "2" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.selectedTransaction
            ? _c(
                "b-modal",
                {
                  attrs: {
                    size: "lg",
                    title: "Transaction ID " + _vm.selectedTransaction.id,
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.selectedTransaction = null
                    },
                    cancel: function($event) {
                      _vm.selectedTransaction = null
                    }
                  },
                  model: {
                    value: _vm.showTransactionDetail,
                    callback: function($$v) {
                      _vm.showTransactionDetail = $$v
                    },
                    expression: "showTransactionDetail"
                  }
                },
                [
                  _c("TransactionDetail", {
                    attrs: { transaction: _vm.selectedTransaction }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }