var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "pb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c(
                "b-table-simple",
                { staticClass: "text-left", attrs: { small: "" } },
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("Company:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.company))])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("From:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.sender))])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("To:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.recipient))])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("Email:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.email))])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("Sent:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.created))])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { staticClass: "text-right" }, [
                        _vm._v("Read:")
                      ]),
                      _c("b-td", [_vm._v(_vm._s(_vm.message.viewed))])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.message.documents && _vm.message.documents.length
                ? _c("div", [
                    _c("hr"),
                    _c("h4", { staticClass: "text-center m-0" }, [
                      _vm._v("Attachments")
                    ]),
                    _c(
                      "ul",
                      { staticClass: "text-left ml-5" },
                      _vm._l(_vm.message.documents, function(d) {
                        return _c("li", { key: d.id }, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "black" },
                              attrs: { href: d.url }
                            },
                            [_vm._v(_vm._s(d.documentname))]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left", attrs: { cols: "12", md: "7" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", [
                    _c("h4", { staticClass: "m-0" }, [
                      _vm._v("Subject: " + _vm._s(_vm.message.subject))
                    ])
                  ]),
                  _c("b-card-text", { staticClass: "m-3" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.message.content) }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "modal-footer" },
        [
          _c(
            "b-col",
            { staticClass: "text-left m-0 p-0" },
            [
              _vm.viewed
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.markAsUnread.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Mark As Unread")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right m-0 p-0" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }