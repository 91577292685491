var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    { staticClass: "text-left" },
    [
      _c(
        "b-tbody",
        [
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [_vm._v("Account:")]),
              _c("b-td", [
                _vm._v(
                  _vm._s(
                    _vm.transaction.plaidaccount
                      ? _vm.transaction.plaidaccount
                      : _vm.transaction.linkedqbaccount
                  )
                )
              ])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Account Type:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.plaidaccounttype))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Business Date:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.businessdate))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [_vm._v("Amount:")]),
              _c("b-td", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.transaction.amount)))
              ])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Transaction Name:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.name))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [_vm._v("Category:")]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.category))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Merchant Name:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.data.merchant_name))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Payment Channel:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.data.payment_channel))])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Transaction Type:")
              ]),
              _c("b-td", [
                _vm._v(_vm._s(_vm.transaction.data.transaction_type))
              ])
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticClass: "text-right" }, [
                _vm._v("Check Number:")
              ]),
              _c("b-td", [_vm._v(_vm._s(_vm.transaction.data.check_number))])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }